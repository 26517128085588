import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import Banner from './banner';
import MoblieMenu from "./mobilemenu";
import moment from 'moment';
import TextSlider from './textslide';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: null,
            partner: null,
            Type: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
            typepage: null
        }
        // this.handleClick = this.handleClick.bind(this);
        // this.handlePageChange = this.handlePageChange.bind(this);
        // this.hideAlert = this.hideAlert.bind(this);

    }



    callpercent(gameid) {
        //console.log(this.state.typepage)
        const date = new Date();
        let number = 30 + Math.floor(Math.random() * (100 - 30));
        var tokenx = localStorage.getItem('percentgame');
        let tagpercentlist = [];
        let tagpercent = [];
        if (tokenx != null) {
            const listx = JSON.parse(tokenx);
            var listIndex = listx.filter(x => x.id === gameid);
            // console.log(57, listIndex);
            if (listIndex.length == 0) {
                //  console.log(gameid, 59);
                let list = {
                    id: gameid,
                    percent: number,
                    datetime: date
                }
                listx.push(list)
                tagpercent.push(listIndex)
                localStorage.setItem('percentgame', JSON.stringify(listx))
            } else {
                tagpercent = listIndex
                var created = new Date();
                const startDate = moment(listIndex[0].datetime);
                const timeEnd = moment(created);
                const diff = timeEnd.diff(startDate);
                const diffDuration = moment.duration(diff);
                // console.log(777, diffDuration.minutes(), gameid)
                if (diffDuration.minutes() > 30) {
                    listx.filter(x => x.id === gameid).map(function (obj) {
                        obj.number = number
                        obj.datetime = date
                        return obj
                    })
                    localStorage.setItem('percentgame', JSON.stringify(listx))
                    console.log("finish", gameid)
                }
            }
        }
        else {
            let list = {
                id: gameid,
                percent: number,
                datetime: date
            }
            tagpercent.push(list)
            tagpercentlist.push(list)
            localStorage.setItem('percentgame', JSON.stringify(tagpercentlist))
        }
        //   console.log(92, tagpercent)
        if (this.state.typepage != "CASINO" && this.state.typepage != "SPORT") {
            let pop = (
                <div>
                    <div className="progress position-progress mt-1 position-progress-g"
                        role="progressbar"
                        aria-label="Example with label"
                        aria-valuenow={tagpercent[0].percent}
                        aria-valuemin={0}
                        aria-valuemax={100}
                    >
                        <div
                            className="progress-bar bg-rate-persent text-web"
                            style={{
                                width: parseInt(tagpercent[0].percent) + "%",
                                background: (tagpercent[0].percent > 0 && tagpercent[0].percent < 50) ? "rgb(254, 215, 25)" : (tagpercent[0].percent >= 50 && tagpercent[0].percent < 70) ? " rgb(2, 119, 189)" : "rgb(37, 155, 36)"
                            }}
                        />
                    </div>
                    <div className="text-win-rate">
                        <small className="text-size-win-rate">
                            อัตราชนะ
                        </small>
                        <b className="p-1 text-size-win-rate-number">
                            {tagpercent[0].percent}%
                        </b>
                    </div>
                </div>
            )
            return pop
        }
        else {
            return ""
        }


    }


    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        console.log(userAgent);
        this.checklogin();
        if (!this.state.loginstate) {
            //////console.log(5465656);
            window.location.href = '/signin';

            // return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {

            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                System: Systems,
                playerUsername: this.state.userid,
                isMobile: isMobile,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined)
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {
                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }

    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            //////console.log(decoded);
            this.setState({
                userid: decoded.message.playerid,
                username: decoded.message.playerid,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    componentDidMount() {
        this.checklogin();
        this.checkonline();
    }
    addDefaultSrc(ev, listitem) {
        const origin = listitem.split("http");
        if (origin.length > 1) {
            const origin = listitem.split("https");
            if (origin.length > 1) return listitem;
            else return listitem.replace("http", "https");
        }
        else {
            return "https://ardmhzelxcmj.ocrazeckyunc.com" + listitem

        }

    }
    checkonline() {
        //////console.log(1);
        const search = window.location.href;
        const params = new URLSearchParams(search);
        const space = search.split("/")
        this.setState({
            typepage: space[4].replace("#", "").toLocaleUpperCase(),
            partner: space[5].replace("#", ""),
        });
        instance.post("/api/v1/tabgamelist", {
            state: "999",
            System: Systems,
            partner: space[5].replace("#", ""),
            game: space[4].replace("#", ""),
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
    }

    // copyToClipboard = (e) => {
    //     e.preventDefault();
    //     var categorylist = this.state.categorylist;
    //     let searchStr = this.state.formData["sec"];
    //     //////console.log(searchStr);
    //     //////console.log(categorylist);
    //     if (searchStr !== "") {
    //         let matches = categorylist.filter(t => t.description.toLowerCase().includes(searchStr));
    //         //////console.log(matches);
    //         this.setState({
    //             categorylist: matches,
    //         });
    //     } else {
    //         this.checkonline();
    //     }
    // };

    // handleInputChange = (event) => {
    //     const target = event.target;
    //     const value = target.value;
    //     const name = target.name;
    //     //////console.log(value);
    //     let { formData } = this.state;
    //     formData[name] = value;
    //     this.setState({
    //         formData: formData,
    //     });
    // }


    render() {
        if (this.state.categorylist.length > 0) {
            return (
                <>
                    <div className="d-lg-none" >

                        <div>
                            <br />
                            <br />
                            <br />
                        </div>
                        <div>
                            <Banner />
                        </div>
                        <div>
                        </div>
                    </div>
                    <MoblieMenu />
                    <div className="-games-index-page scroll-y-menu-left-nav col-9 col-sm-9 col-md-10 col-lg-12 col-xl-12 pl-0 pr-0">
                        <div className="mb-2 text-white-v1">
                            <div className="d-none d-lg-block">
                                <div>
                                    <br />
                                    <br />
                                    <br />
                                </div>
                                <div id="main__content ">{/**/}</div>
                                <Banner />
                                <div id="main__content ">
                                    <TextSlider />
                                </div>
                                {/**/}
                            </div>
                            {/**/}
                            <div className="padding-router-view">
                                <div>
                                    <main>
                                        <div className="pt-1 pb-3 text-white-v1 w-scroll-height">
                                            <div className="x-category-provider js-game-scroll-container js-game-container animated animatedFadeInUp fadeInUp">
                                                <div className="-games-list-container container">
                                                    <div className="text-left">
                                                        <div className="-inner-wrapper text-left">
                                                            <div className="bg-game-list mt-2">
                                                                <div className="d-flex justify-content-between p-2">
                                                                    <div>
                                                                        <a role="button" href="/">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={16}
                                                                            height={16}
                                                                            fill="currentColor"
                                                                            className="bi bi-arrow-left-circle text-color-back"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                                                                            />
                                                                        </svg>
                                                                        </a>
                                                                    </div>
                                                                    <div />
                                                                    <div>
                                                                        <small>
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={20}
                                                                                height={20}
                                                                                fill="currentColor"
                                                                                className="bi bi-controller text-color-back"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1v-1z" />
                                                                                <path d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729c.14.09.266.19.373.297.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466.206.875.34 1.78.364 2.606.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527-1.627 0-2.496.723-3.224 1.527-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.34 2.34 0 0 1 .433-.335.504.504 0 0 1-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a13.748 13.748 0 0 0-.748 2.295 12.351 12.351 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.354 12.354 0 0 0-.339-2.406 13.753 13.753 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27-1.036 0-2.063.091-2.913.27z" />
                                                                            </svg>{" "}
                                                                            {this.state.typepage} &gt;    {this.state.partner}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/**/}
                                                    </div>
                                                    <nav className="nav-menu mt-1" id="navbarProvider">
                                                        <ul className="nav nav-pills row animated animatedFadeInUp fadeInUp">
                                                            {this.state.categorylist.map(listitem => (
                                                                <li
                                                                    key={listitem.id}
                                                                    id={listitem.id}
                                                                    className="col-6 col-lg-2 col-md-3 nav-item -random-container -game-casino-macro-container p-1 pb-0 mb-2"
                                                                >
                                                                    <a
                                                                        href={() => false}
                                                                        onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                                    >
                                                                        <div
                                                                            className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable example bg-card-item"
                                                                            data-status="-cannot-entry -untestable bg-card-item"
                                                                        >
                                                                            <div className="-inner-wrapper card-width-banner-games">
                                                                                <picture>
                                                                                    <img
                                                                                        role="button"
                                                                                        className="w-100 hover-slot hoverTextCss hoverTextCss-padding"
                                                                                        lazy="loaded"
                                                                                        src={this.addDefaultSrc(null, listitem.image)}
                                                                                    />
                                                                                </picture>
                                                                                <div className="-overlay d-none d-lg-block">
                                                                                    <div className="-overlay-inner">
                                                                                        <div className="-wrapper-container">
                                                                                            <a
                                                                                                role="button"
                                                                                                className="-btn v-login js-account-approve-aware"
                                                                                                data-toggle="modal"
                                                                                                data-target="#loginModal"
                                                                                            >
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width={20}
                                                                                                    height={20}
                                                                                                    fill="currentColor"
                                                                                                    className="bi bi-play-circle-fill m-2"
                                                                                                    viewBox="0 0 16 16"
                                                                                                >
                                                                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                                                                                                </svg>
                                                                                                <span className="-text-btn">เข้าเล่น</span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div>
                                                                                    <div>
                                                                                        <div className="progress-web position-progress-data p-2">
                                                                                            <div>
                                                                                                {this.callpercent(listitem.gameId)}
                                                                                                <div>
                                                                                                    <div className="name-progress-circle-number text-name text-ellipsis d-flex justify-content-between align-items-center">
                                                                                                        <div>
                                                                                                            <b className="text-ellipsis">
                                                                                                                {listitem.description}
                                                                                                            </b>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <svg
                                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                                width={16}
                                                                                                                height={16}
                                                                                                                fill="currentColor"
                                                                                                                className="bi bi-star"
                                                                                                                viewBox="0 0 16 16"
                                                                                                            >
                                                                                                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="color-c-g">{listitem.partner}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </div>

                </>

            );
        } else {

        }
    }
}
export default App;
