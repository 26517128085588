import React, { Component } from 'react'; //different
import './App.css';
import Carousel from './carousel';
class App extends Component {


    onClick = (e) => {
        e.preventDefault()
        ////console.log('onclick..')
    }
    render() {
        return (
            <>
                <div>
                    <div className="box-class">
                        <div>
                            <div className="bg-view pt-3 pb-3">
                                <div className="container">
                                    <div className="page-view-index text-center">
                                        {/**/}
                                        <div className="logo-web">
                                            <img
                                                src="/build/web/igame-index-lobby-wm/img/logo.svg?v=1"
                                                className="logo-web-page"
                                                alt=""
                                            />
                                        </div>
                                        <div className="details-page-view text-left" >
                                            <div className="js-replace-heading-sub-title-seo-container animated animatedFadeInUp fadeInUp mt-2 text-left">
                                                <h2 className="-heading-sub-title m-0 p-2-view-page">
                                                    <b>
                                                        Unibet เว็บที่ให้บริการคาสิโนออนไลน์เต็มรูปแบบ
                                                        ทั้งสล็อตออนไลน์ คาสิโนสด บาคาร่าสด เกมแทงปลา เกมกีฬา ฯลฯ
                                                    </b>
                                                </h2>
                                                <p className="text-details-box">
                                                    โดยผู้เล่นสามารถสนุกกับเกมของเราได้ด้วยเงินเดิมพันขั้นต่ำเพียง
                                                    10 บาทเท่านั้น และสามารถทำการฝาก-ถอนได้ตลอด 24 ชั่วโมง
                                                    ด้วยระบบออโต้ ทั้งสะดวก
                                                    รวดเร็วทันใจเป็นอย่างมากเหมาะสำหรับผู้ที่ชื่นชอบการเดิมพันในรูปแบบของเกมการพนันออนไลน์อันดับ
                                                    1 ในปี 2021
                                                </p>
                                                <h2 className="-heading-sub-title m-0 p-2-view-page">
                                                    <b>
                                                        Unibet บริการประทับใจ ก็ไม่เป็นสองรองใคร ใส่ใจทุกคำตอบ ตลอด
                                                        24 ชั่วโมง 7 วันต่อสัปดาห์ไม่มีหยุด
                                                    </b>
                                                </h2>
                                                <p className="text-details-box">
                                                    เพราะผู้เล่นทุกท่าน คือ คนสำคัญของเรา
                                                    และเรายังมีทีมลูกค้าสัมพันธ์ที่คอยให้ความช่วยเหลือ ตอบทุกคำถาม
                                                    ให้บริการทุกเรื่องที่คุณสงสัยตลอด 24 ชั่วโมง
                                                    เพื่อให้คนสำคัญของเราไม่ต้องกังวลกับปัญหาที่พบตั้งแต่ต้นจนจบ
                                                    นอกจากนี้ เรายังมีเว็บคาสิโน
                                                    และสล็อตพันธมิตรชั้นนำที่พร้อมมาเสิร์ฟความหลากหลายของเกม
                                                    และความสนุกสนาน ให้คุณได้เพลิดเพลิน เช่น SA Gaming, Unibet
                                                    Gaming, WM Casino, Dream Gaming, Pretty Gaming, ALLBET, Joker,
                                                    PG Slot และอื่นๆ อีกมากมายจนผู้เล่นจะติดใจอย่างแน่นอน
                                                </p>
                                            </div>
                                        </div>
                                        {/**/}
                                        {/**/}
                                        <div
                                            className="row"
                                            data-id="12d4edb6"
                                            data-element_type="container"
                                            data-settings='{"content_width":"boxed"}'
                                        >
                                            <div
                                                className="elementor-element elementor-element-2982ecdc e-con-full e-flex e-con col-12 col-md-4"
                                                data-id="2982ecdc"
                                                data-element_type="container"
                                                data-settings='{"content_width":"full"}'
                                            >
                                                <div
                                                    className="elementor-element elementor-element-46eac4a6 elementor-widget frame-2-black elementor-widget-image mb-3"
                                                    data-id="46eac4a6"
                                                    data-element_type="widget"
                                                    data-widget_type="image.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        {/**/}
                                                        <img
                                                            decoding="async"
                                                            width={400}
                                                            height={300}
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/4c274881-0b91-494d-e091-319538b91700/public"
                                                            className="attachment-large size-large wp-image-64 entered lazyloaded"
                                                            alt=""
                                                            data-lazy-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/4c274881-0b91-494d-e091-319538b91700/public"
                                                            data-lazy-sizes="(max-width: 400px) 100vw, 400px"
                                                            data-lazy-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/4c274881-0b91-494d-e091-319538b91700/public"
                                                            data-ll-status="loaded"
                                                            sizes="(max-width: 400px) 100vw, 400px"
                                                            srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/4c274881-0b91-494d-e091-319538b91700/public"
                                                        />
                                                        <noscript>
                                                            &lt;img decoding="async" :src="hot_img_1"
                                                            class="attachment-large size-large wp-image-64" alt=""
                                                            :srcset="hot_img_1" /&gt;
                                                        </noscript>
                                                    </div>
                                                    <div className="elementor-widget-container p-3 text-left">
                                                        <p className="text-details-box">
                                                            ผู้ริเริ่มและผู้ให้บริการเกมคาสิโนชั้นนำรวบรวมเอาไว้อย่างครบครัน
                                                            ตอบโจทย์ได้ดีที่สุด มีเกมให้เลือกหลากหลาย เล่นง่าย
                                                            ไม่รู้สึกเบื่อ ระบบที่มีความเสถียรภาพ
                                                            พัฒนาให้มีความทันสมัยอยู่ตลอดเวลา
                                                            สัมผัสบรรยากาศที่สมจริงถ่ายทอดสดให้ได้ร่วมติดตามผลการเดิมพันอย่างใกล้ชิด
                                                            ลุ้นสนุกตลอด 24 ชั่วโมง evocasino เปิดประสบการณ์ใหม่ๆ
                                                            สร้างความตื่นตาตื่นใจได้เป็นอย่างดี
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-2982ecdc e-con-full e-flex e-con col-12 col-md-4"
                                                data-id="2982ecdc"
                                                data-element_type="container"
                                                data-settings='{"content_width":"full"}'
                                            >
                                                <div
                                                    className="elementor-element elementor-element-46eac4a6 elementor-widget frame-2-black elementor-widget-image mb-3"
                                                    data-id="46eac4a6"
                                                    data-element_type="widget"
                                                    data-widget_type="image.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        {/**/}
                                                        <img
                                                            decoding="async"
                                                            width={400}
                                                            height={300}
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/ef6d4562-8d14-4943-5d30-88547959dd00/public"
                                                            className="attachment-large size-large wp-image-64 entered lazyloaded"
                                                            alt=""
                                                            data-lazy-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/ef6d4562-8d14-4943-5d30-88547959dd00/public"
                                                            data-lazy-sizes="(max-width: 400px) 100vw, 400px"
                                                            data-lazy-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/ef6d4562-8d14-4943-5d30-88547959dd00/public"
                                                            data-ll-status="loaded"
                                                            sizes="(max-width: 400px) 100vw, 400px"
                                                            srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/ef6d4562-8d14-4943-5d30-88547959dd00/public"
                                                        />
                                                        <noscript>
                                                            &lt;img decoding="async" :src="hot_img_2"
                                                            class="attachment-large size-large wp-image-64" alt=""
                                                            :srcset="hot_img_2" /&gt;
                                                        </noscript>
                                                    </div>
                                                    <div className="elementor-widget-container p-3 text-left">
                                                        <p className="text-details-box">
                                                            ผู้ริเริ่มและผู้ให้บริการเกมคาสิโนชั้นนำรวบรวมเอาไว้อย่างครบครัน
                                                            ตอบโจทย์ได้ดีที่สุด มีเกมให้เลือกหลากหลาย เล่นง่าย
                                                            ไม่รู้สึกเบื่อ ระบบที่มีความเสถียรภาพ
                                                            พัฒนาให้มีความทันสมัยอยู่ตลอดเวลา
                                                            สัมผัสบรรยากาศที่สมจริงถ่ายทอดสดให้ได้ร่วมติดตามผลการเดิมพันอย่างใกล้ชิด
                                                            ลุ้นสนุกตลอด 24 ชั่วโมง evocasino เปิดประสบการณ์ใหม่ๆ
                                                            สร้างความตื่นตาตื่นใจได้เป็นอย่างดี
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-2982ecdc e-con-full e-flex e-con col-12 col-md-4"
                                                data-id="2982ecdc"
                                                data-element_type="container"
                                                data-settings='{"content_width":"full"}'
                                            >
                                                <div
                                                    className="elementor-element elementor-element-46eac4a6 elementor-widget frame-2-black elementor-widget-image mb-3"
                                                    data-id="46eac4a6"
                                                    data-element_type="widget"
                                                    data-widget_type="image.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        {/**/}
                                                        <img
                                                            decoding="async"
                                                            width={400}
                                                            height={300}
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/56266019-b3c7-4052-597c-21d641460700/public"
                                                            className="attachment-large size-large wp-image-64 entered lazyloaded"
                                                            alt=""
                                                            data-lazy-srcset="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/56266019-b3c7-4052-597c-21d641460700/public"
                                                            data-lazy-sizes="(max-width: 400px) 100vw, 400px"
                                                            data-lazy-src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/56266019-b3c7-4052-597c-21d641460700/public"
                                                            data-ll-status="loaded"
                                                            sizes="(max-width: 400px) 100vw, 400px"
                                                            srcSet="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/56266019-b3c7-4052-597c-21d641460700/public"
                                                        />
                                                        <noscript>
                                                            &lt;img decoding="async" :src="hot_img_3"
                                                            class="attachment-large size-large wp-image-64" alt=""
                                                            :srcset="hot_img_3" /&gt;
                                                        </noscript>
                                                    </div>
                                                    <div className="elementor-widget-container p-3 text-left">
                                                        <p className="text-details-box">
                                                            ผู้ริเริ่มและผู้ให้บริการเกมคาสิโนชั้นนำรวบรวมเอาไว้อย่างครบครัน
                                                            ตอบโจทย์ได้ดีที่สุด มีเกมให้เลือกหลากหลาย เล่นง่าย
                                                            ไม่รู้สึกเบื่อ ระบบที่มีความเสถียรภาพ
                                                            พัฒนาให้มีความทันสมัยอยู่ตลอดเวลา
                                                            สัมผัสบรรยากาศที่สมจริงถ่ายทอดสดให้ได้ร่วมติดตามผลการเดิมพันอย่างใกล้ชิด
                                                            ลุ้นสนุกตลอด 24 ชั่วโมง evocasino เปิดประสบการณ์ใหม่ๆ
                                                            สร้างความตื่นตาตื่นใจได้เป็นอย่างดี
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/**/}
                                    {/**/}
                                    {/**/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Carousel />
            </>

        );
    }
}
export default App;
